import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import utils from "../utils";
import store from "@/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,
    meta: {
      title: "千途赛事 一站式摄影赛事互联网服务平台",
      content: {
        keywords: "千途赛事",
        description: "千途赛事，打造一站式摄影赛事互联网服务平台",
      },
    },
  },
  {
    path: "/detail/:id",
    component: () =>
      import(/* webpackChunkName: "detail" */ "../views/Detail/"),
    children: [
      {
        path: "",
        redirect: "intro",
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/Intro"),
      },
      {
        path: "intro",
        name: "intro",
        meta: {
          title: "",
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/Intro"),
      },
      {
        path: "notice",
        name: "notice",
        meta: {
          title: "获奖公告",
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/notice"),
      },
      {
        path: "works",
        name: "works",
        meta: {
          title: "已投稿作品",
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/Works"),
      },
      {
        path: "myworks",
        name: "myworks",
        meta: {
          title: "我的作品",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/Myworks"),
      },
      {
        path: "award",
        name: "award",
        meta: {
          title: "获奖作品",
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/Award"),
      },
      {
        path: "groupinfo",
        name: "groupinfo",
        meta: {
          title: "战队信息",
        },
        component: () =>
          import(/* webpackChunkName: "detail" */ "../views/Detail/groupinfo"),
      },
    ],
  },
  {
    path: "/contribute/:id/:type",
    meta: {
      needLogin: true,
    },
    component: () =>
      import(/* webpackChunkName: "contribute" */ "../views/Contribute/"),
    children: [
      {
        // 空路由，指向根路径
        path: "",
        name: "contribute",
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Main"
          ),
      },
      {
        path: "single",
        name: "single",
        meta: {
          title: "单图投稿",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Single"
          ),
      },
      {
        path: "multi",
        name: "multi",
        meta: {
          title: "多图投稿",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Multi"
          ),
      },
      {
        path: "group",
        name: "group",
        meta: {
          title: "组照投稿",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Group"
          ),
      },
      {
        path: "video",
        name: "video",
        meta: {
          title: "视频投稿",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Video"
          ),
      },
      {
        path: "team",
        name: "team-c",
        meta: {
          title: "战队投稿",
        },
        // meta: {
        //   needLogin: true
        // },
        component: () =>
          import(
            /* webpackChunkName: "contribute" */ "../views/Contribute/Team"
          ),
      },
    ],
  },
  {
    path: "/month/:id",
    name: "month",
    meta: {
      title: "月赛",
    },
    component: () => import(/* webpackChunkName: "month" */ "../views/Month"),
  },
  {
    path: "/team/:id",
    name: "team",
    meta: {
      title: "战队详情",
    },
    component: () =>
      import(/* webpackChunkName: "month" */ "../views/Detail/Team"),
  },
  {
    path: "/rank/:id",
    name: "rank",
    component: () => import(/* webpackChunkName: "rank" */ "../views/Rank"),
  },
  {
    path: "/detail-info/:id",
    name: "detail-info",
    meta: {
      title: "赛事详情页",
    },
    component: () =>
      import(/* webpackChunkName: "rank" */ "../views/DetailInfo"),
  },
  // {
  //   path: "",
  //   name: '',
  //   component: ()=> import(/* webpackChunkName: "" */ "../views/")
  // },
  {
    path: "/404",
    name: "not-found",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "error" */ "../views/404.vue"),
  },
  {
    path: "/uploadOriImg/:id",
    name: "uploadOriImg",
    meta: {
      title: "获奖大图上传",
    },
    component: () =>
      import(/* webpackChunkName: "uploadOriImg" */ "../views/uploadOriImg"),
  },
  {
    path: "/theme/demo",
    name: "demo",
    meta: {
      title: "DEMO",
    },
    component: () =>
      import("../views/Theme/demo"),
  },
  {
    path: "/theme/jx1/:id",
    name: "jx1",
    meta: {
      title: "单图投稿",
    },
    component: () =>
      import("../views/Theme/jx1"),
  },
  {
    path: "/theme/jx2/:id",
    name: "jx2",
    meta: {
      title: "多图投稿",
    },
    component: () =>
      import("../views/Theme/jx2"),
  },
  {
    path: "/theme/jx3/:id",
    name: "jx3",
    meta: {
      title: "单图投稿",
    },
    component: () =>
      import("../views/Theme/jx3"),
  },
  {
    path: "/theme/jx4/:id",
    name: "jx4",
    meta: {
      title: "单图投稿",
    },
    component: () =>
      import("../views/Theme/jx4"),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // console.log(to.meta.content);
  // 根据路由配置title
  if (to.meta.content) {
    let head = document.getElementsByTagName("head");
    let meta = document.createElement("meta");
    document
      .querySelector('meta[name="keywords"]')
      .setAttribute("content", to.meta.content.keywords);
    document
      .querySelector('meta[name="description"]')
      .setAttribute("content", to.meta.content.description);
    meta.content = to.meta.content;
    head[0].appendChild(meta);
  }
  if (to.meta && to.meta.title) {
    window.document.title = to.meta.title;
  }

  // 判断是否登录
  // 放在2.0统一上线后做
  // 登录后初始化用户信息操作集合到utils中
  // utils.checkLogin(axios, 'init');

  // 判断是否需要登录的路由跳转，目前只需要判断我的作品页和投稿页
  // if (to.meta.needLogin && !userInfo.isLogin) {
  //   // 需要登录，并且未登录，则转去登录
  //   // 如果已登录 next()
  // }
  if (to.meta.needLogin && !store.state.isLogin) {
    // 需要登录，并且未登录，则转去登录

    // store.commit('changeLogin', 'gogogo');
    // console.log(store.state.isLogin);
    utils.checkLogin();
    // 如果已登录 next()
  }

  next();
});
// 跳转后返回顶部
router.afterEach(() => {
  window.scrollTo(0, 0);
});

export default router;
