<template>
  <div class="footer">
    <div class="wrap clearfix">
      <div class="left">
        <p>
          千途网是一个为全球摄影师提供在线图片制作，摄影拍摄点导航，图片分享、销售，摄影学习、交流的综合摄影服务平台。
        </p>
        <p>
          <a href="aboutus.html">关于千途</a><span class="icon-line">|</span>
          <a href="">使用指南</a><span class="icon-line">|</span>
          <a href="">修图助手</a><span class="icon-line">|</span>
          <a href="">在线客服</a><span class="icon-line">|</span>
          <a href="">意见反馈</a><span class="icon-line">|</span>
          <a href="disclaimer.html">免责声明</a>
        </p>
        <p class="icp">
          ©2019 福州全摄汇网络科技有限公司版权所有，<a
            href="https://beian.miit.gov.cn/"
            target="_blank"
            ><img :src="beian" alt="" />闽ICP备19009816号-2</a
          >
        </p>
      </div>
      <div class="right clearfix">
        <div class="code">
          <img :src="qrcode" />
          <p>下载千途App<br />手机上查看与分享修图作品</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      qrcode: require("../static/res/images/code.png"),
      beian: require("../static/images/beian-icon.png")
    };
  }
};
</script>

<style></style>
