<template>
  <div class="header">
    <div class="wrap">
      <a class="logo" href="index.html"></a>
      <ul class="nav clearfix">
        <li>
          <a href="javascript:void (0)" @click="jumpClick('')"
            ><p class="chn">首页</p>
            <p class="eng">HOME</p></a
          >
        </li>
        <li>
          <a href="//ai.pai2345.com/web/#/">
            <p class="chn">AI影像百宝箱</p><p class="eng">AI BOXS</p>
          </a>
        </li>
        <li>
          <a href="javascript:void (0)" @click="jumpClick('/web/ps')"
            ><p class="chn">修图</p>
            <p class="eng">RETOUCH</p></a
          >
        </li>
        <li class="active">
          <a :href="myUrl"
            ><p class="chn">比赛</p>
            <p class="eng">MATCH</p></a
          >
        </li>
        <li>
          <a
            href="javascript:"
            @mouseenter="showDrop = true"
            @mouseleave="showDrop = false"
            ><p class="chn">发布</p>
            <p class="eng">EXPLORE</p>
            <i class="arrow"></i
          ></a>
          <div
            class="sub"
            v-show="showDrop"
            @mouseenter="showDrop = true"
            @mouseleave="showDrop = false"
          >
            <a
              class="sub-item"
              href="javascript:void (0)" @click="loginJumpClick('/web/findworks/addShoot')"
              >发布摄影作品</a
            >
            <a
              class="sub-item"
              href="javascript:void (0)" @click="loginJumpClick('/web/findworks/addTravels')"
              >发布游记</a
            >
            <a
              class="sub-item"
              href="javascript:void (0)" @click="loginJumpClick('/web/findworks/index')"
              onclick="sessionStorage.setItem('initType',3);"
              >已发布管理</a
            >
            <a
              class="sub-item"
              href="javascript:void (0)" @click="loginJumpClick('/web/findworks/draft')"
              onclick="sessionStorage.setItem('initType',3);"
              >未完成任务</a
            >
          </div>
        </li>
        <li>
          <a href="//edu.pai2345.com/"
            ><p class="chn">云课堂</p>
            <p class="eng">EDUCATION</p></a
          >
        </li>
        <li>
          <a href="//www.pai2345.com/qtclub_web/#/"
            ><p class="chn">五六公社</p>
            <p class="eng">WULIU</p></a
          >
        </li>
      </ul>
      <ul class="user-logwrap right">
        <li
          v-if="isLogin"
          class="user-logwrap-item clearfix"
          @mouseenter="isShowUserBox = true"
          @mouseleave="isShowUserBox = false"
        >
          <a class="user-logged" href="javascript:void (0)" @click="loginJumpClick('/web/member/index')">
            <img class="user-face-pic" :src="userInfo.avatar" />
            <span class="user-name">{{ userInfo.nickname }}</span>
          </a>
          <div
            class="user-box"
            v-show="isShowUserBox"
            @mouseenter="isShowUserBox = true"
            @mouseleave="isShowUserBox = false"
          >
            <div class="user-info clearfix">
              <div class="user-info-1 user-face left">
                <img class="user-face-pic" :src="userInfo.avatar" />
                <i class="icon-vip"></i>
              </div>
              <div class="user-info-2 left">
                <div class="user-name">{{ userInfo.nickname }}</div>
                <div class="user-money">
                  <span class="user-money-item"
                    ><i class="icon-coin"></i>{{ userInfo.coin }}</span
                  >
                  <span class="user-money-item"
                    ><i class="icon-bean"></i>{{ userInfo.ticket }}</span
                  >
                </div>
              </div>
              <div class="user-info-3 right">
                <a class="logout" href="javascript:void (0)" @click="loginOut"><i class="icon-logout"></i>退出</a>
                <a class="btn" href="javascript:void (0)" @click="loginJumpClick('/web/combo/index')">充值</a>
              </div>
            </div>
            <div class="user-link">
              <a class="user-link-item" href="javascript:void (0)" @click="loginJumpClick('/web/member/index#/order')">
                <p><i class="icon-mygallery"></i></p>
                <p>云图库</p>
              </a>
              <a class="user-link-item" href="javascript:void (0)" @click="loginJumpClick('/web/member/index#/letter')">
                <p>
                  <i class="icon-mymessage"
                    ><span class="layui-badge">{{ userInfo.no_read_num }}</span></i
                  >
                </p>
                <p>站内信</p>
              </a>
              <a class="user-link-item" href="https://www.pai2345.com/web/member/index#/proxy-order">
								<p><i class="icon-autoJoin"></i></p>
								<p>自动投稿</p>
							</a>
              <a class="user-link-item" href="//imgstore.pai2345.com/web/#/usercenter/likes">
								<p><i class="icon-myLikes"></i></p>
								<p>收藏夹</p>
							</a>
              <a class="user-link-item" href="javascript:void (0)" @click="loginJumpClick('/web/member/index#/gallery')">
                <p><i class="icon-myorder"></i></p>
                <p>我的云盘</p>
              </a>
              <a class="user-link-item" href="javascript:void (0)" @click="loginJumpClick('/web/member/index#/vip')">
                <p><i class="icon-myvip"></i></p>
                <p>我的贵族</p>
              </a>
            </div>
          </div>
        </li>
        <li v-if="!isLogin" class="user-logwrap-item clearfix">
          <a class="toreg" href="javascript:void (0)" @click="loginClick()">注册</a><i class="icon-line">|</i>
          <a class="tologin" href="javascript:void (0)" @click="loginClick()">登录</a>
        </li>
      </ul>
      <ul class="user-logwrap cloud-gallery right" v-if="isLogin">
        <li class="user-logwrap-item">
          <a class="toreg" href="javascript:void (0)" @click="jumpClick('/web/member/index')">云图库</a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLogin: true,
      isShowUserBox: false,
      userInfo: {
        avatar: require("../static/res/images/user.png"),
        nickname: "",
      },
      showDrop: false,
      myUrl:process.env.VUE_APP_BASE_URL,
    };
  },
    methods: {
        loginClick(){
            /*eslint-disable*/
            passportLogin.login();
            /*eslint-enable*/
        },
        jumpClick(str){
            let url =  process.env.VUE_APP_JUMP_URL + str;
            window.location.href = url;
        },
        loginJumpClick(str){
            if(this.isLogin){
                let url =  process.env.VUE_APP_JUMP_URL + str;
                window.location.href = url;
            }else {
                /*eslint-disable*/
                passportLogin.login();
                /*eslint-enable*/
            }
        },
        loginOut(){
            this.axios.post("/match/passloginout").then(res => {
                console.log(res);
                window.location.reload();
            })
        }
    },
    created() {
        this.axios.post("/match/get_user_info").then(res => {
            if(res.data.data ==0){
                this.isLogin = false;
            }else {
                this.userInfo = res.data.data;
            }
        })
    },
};
</script>

<style lang="scss">
.cloud-gallery {
  margin-right: 30px;
}
.layui-badge, .layui-badge-dot, .layui-badge-rim {
  display: inline-block;
  font-size: 12px;
  text-align: center;
  background-color: #FF5722;
  color: #fff;
}
</style>
