<template>
  <div id="app">
    <!-- <el-scrollbar> -->
    <Head />
    <router-view v-if="isRouterAlive" />
    <Foot />
    <!-- 首页左侧广告位 -->
    <!-- <div class="ad-left-fixed" v-if="displayAd">
      <a href="https://www.pai2345.com/web/proxymatch/index">
        <img src="@/assets/ad.png" class="add-img" alt="广告位" />
        <i class="el-icon-close" @click="closeAdHandler"></i
      ></a>
    </div> -->
    <!-- 首页左侧广告位 -->
    <ul class="fixed-bar" id="fixed-bar">
      <li class="gotop" @click="goTop()" v-show="showGotop">
        <a></a>
        <div class="subbox">
          <div>返回顶部</div>
        </div>
      </li>
      <li class="one-click">
        <a href="https://www.pai2345.com/web/member/index#/proxy-order" target="_blank">自动<br>投稿</a>
        <div class="subbox">
            <a href="https://www.pai2345.com/web/member/index#/proxy-order">
              <img src="https://www.pai2345.com/static/web/res/images/ad-2021-1-28-r.png" alt="">
            </a>
        </div>
      </li>
      <li class="one-click">
        <a href="//imgstore.pai2345.com" target="_blank">签约<br>售图</a>
        <div class="subbox">
            <a href="//imgstore.pai2345.com">
              <img src="https://www.pai2345.com/static/web/res/images/210429/ad-210429-1.png" alt="">
            </a>
        </div>
      </li>
      <li class="service">
        <a></a>
        <div class="subbox">
          <div class="t-1"><i></i>在线客服</div>
          <div>工作日：9:00 - 22:00</div>
          <div>节假日：9:00 - 18:00</div>
          <a
            class="btn-serv"
            target="_blank"
            href="http://wpa.qq.com/msgrd?v=3&amp;uin=3298507498&amp;site=qq&amp;menu=yes"
            >联系客服</a
          >
          <div class="t-2">
            <a href="http://www.pai2345.com/web/ps/qa" target="_blank"
              ><i></i>常见问题&gt;&gt;</a
            >
          </div>
        </div>
      </li>
      <li class="download">
        <a></a>
        <div class="subbox">
          <div>
            <img class="qrcode" :src="qrcode" />
          </div>
          <div>扫码立即下载千途App</div>
        </div>
      </li>
      <li class="video">
        <a>视频<br />教程</a>
        <div class="subbox">
          <div
            :class="cur == index ? 'item item-p on' : 'item item-p'"
            v-for="(item, index) in list"
            :key="index"
            @mouseenter="cur = index"
          >
            <p>{{ item.title1 }}<br />{{ item.title2 }}</p>
            <!-- <a class="pic" :href="item.video" target="_blank" @click="showVideo(item.video)"> -->
            <a class="pic" href="javascript:;" @click="showVideo(index)">
              <img :src="item.image" />
            </a>
          </div>
          <!-- <div class="item item-p">
            <p>Ai修图<br />宣传片</p>
            <a
              class="pic"
              href="javascript:void(0)"
              data-src="http://bf.allimage.cn/330c31040c694e789a904feba912b5e4/8d7e6a97e6c24b6dacf175a22fc5efba-46d9c63fa8fc69968d8e2c47cc649a0e-ld.mp4"
            >
              <img
                src="http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190904/b531ddcd745bc57e3fcc8dd370607a67.jpg"
              />
            </a>
          </div>
          <div class="item item-p">
            <p>Ai修图<br />使用指南</p>
            <a
              class="pic"
              href="javascript:void(0)"
              data-src="http://bf.allimage.cn/663d274d756a43e2a5af097b41b5012f/f5ca7de960fa4d70a71fc44e50c892de-8f24516d3d243233032ac12d29f17a25-ld.mp4"
            >
              <img
                src="http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190903/91deff83e6ed5a4dbe8e8b518a0a89e7.jpg"
              />
            </a>
          </div>
          <div class="item item-p on">
            <p>修图兑换券<br />使用指南</p>
            <a
              class="pic"
              href="javascript:void(0)"
              data-src="http://bf.allimage.cn/0bf782b28e2c4729b5c962165e85e1a1/ce1ce312cbb24c7781e78f0a025e990e-93372f86f5433683d5c2c18a1dffec93-ld.mp4"
            >
              <img
                src="http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190906/3dfd831d885d6e499d3ce0334058f310.jpg"
              />
            </a>
          </div> -->
          <div class="item">
            <a href="http://app.hgzxjy.com.cn/cms/a/53" target="_blank"
              >更多视频&gt;&gt;</a
            >
          </div>
        </div>
      </li>
    </ul>

    <el-dialog class="videoBox" :visible.sync="isShowVideo" width="960" center>
      <div class="tab">
        <div
          class="item"
          :class="cur == index ? 'is-active' : ''"
          v-for="(item, index) in list"
          :key="index"
          :label="item.title1 + item.title2"
          @click="tab(index)"
        >
          {{ item.title1 }}{{ item.title2 }}
        </div>
      </div>
      <video class="fixed-video" controls="" :src="videoLink" autoplay="">
        您的浏览器不支持视频播放。
      </video>
      <!-- <el-tabs type="border-card">
        <el-tab-pane
          :class="cur == index ? 'item item-p on' : 'item item-p'"
          v-for="(item, index) in list"
          :key="index"
          :label="item.title1 + item.title2"
        >
          <video class="fixed-video" controls="" :src="item.video" autoplay="">
            您的浏览器不支持视频播放。
          </video>
        </el-tab-pane>
      </el-tabs> -->
    </el-dialog>
    <!-- </el-scrollbar> -->
  </div>
</template>
<script>
// @ is an alias to /src
import Head from "@/layouts/Head.vue";
import Foot from "@/layouts/Foot.vue";
export default {
  components: {
    Head,
    Foot,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      //返回顶部按钮
      showGotop:false,
      //广告显示
      displayAd: true,
      // 刷新页面
      isRouterAlive: true,
      // 视频
      isShowVideo: false,
      videoLink: "",
      qrcode: require("@/static/res/images/code.png"),
      itemClass: "item item-p",
      cur: 0,
      list: [
        {
          title1: "Ai修图",
          title2: "宣传片",
          video:
            "http://bf.allimage.cn/330c31040c694e789a904feba912b5e4/8d7e6a97e6c24b6dacf175a22fc5efba-46d9c63fa8fc69968d8e2c47cc649a0e-ld.mp4",
          // "https://vdept.bdstatic.com/377a44784364673775544634566b7457/4c506c4e51673369/f2c2a1fba62eff634210dc6dfc73f01f6426a5b9da9bd0de64edc4aabbbee3f25c99bf2ad838f5db7d2eaf1a002703d16df04471feb492c6c6d156c161749a58.mp4?auth_key=1585127759-0-0-ed1fc9d4a187181c08980f4af34b4101",
          image:
            "http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190904/b531ddcd745bc57e3fcc8dd370607a67.jpg",
        },
        {
          title1: "Ai修图",
          title2: "使用指南",
          video:
            "http://bf.allimage.cn/663d274d756a43e2a5af097b41b5012f/f5ca7de960fa4d70a71fc44e50c892de-8f24516d3d243233032ac12d29f17a25-ld.mp4",
          image:
            "http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190903/91deff83e6ed5a4dbe8e8b518a0a89e7.jpg",
        },
        {
          title1: "修图兑换券",
          title2: "使用指南",
          video:
            "http://bf.allimage.cn/0bf782b28e2c4729b5c962165e85e1a1/ce1ce312cbb24c7781e78f0a025e990e-93372f86f5433683d5c2c18a1dffec93-ld.mp4",
          image:
            "http://dy-allimage.oss-cn-hangzhou.aliyuncs.com/uploads/20190906/3dfd831d885d6e499d3ce0334058f310.jpg",
        },
      ],
    };
  },
  mounted(){
    document.addEventListener('scroll',this.handleScroll)
  },
  beforeDestroy() {
    document.removeEventListener('scroll',this.handleScroll)
  },
  methods: {
    handleScroll(e) {
      let scrollTop = e.target.documentElement.scrollTop
      if (scrollTop > 100) {
        this.showGotop = true
      }else{
        this.showGotop = false
      }
    },
    goTop(){
      window.scrollTo(0,0);
    },
    //关闭广告
    closeAdHandler() {
      this.displayAd = !this.displayAd;
    },
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(() => {
        this.isRouterAlive = true;
      });
    },
    showVideo(index) {
      this.videoLink = this.list[index].video;
      this.isShowVideo = true;
      this.cur = index;
    },
    tab(index) {
      this.cur = index;
      this.videoLink = this.list[index].video;
    },
  },
};
</script>
<style lang="scss">
// #app {
//   overflow: scroll;
//   overflow-x: hidden;
//   width: 100%;
//   height: 100vh;
// }
// .el-scrollbar {
//   height: 100vh;
// }
// .el-scrollbar__wrap {
//   overflow: hidden;
//   width: 100%;
//   height: 120%;
// }
#fixed-bar li .subbox {
  display: none;
}
#fixed-bar li:hover .subbox {
  display: block;
}
#fixed-bar li.video:hover .subbox {
  display: flex;
}
.one-click:hover {

}
.fixed-video {
  width: 960px;
  height: 540px;
}
.videoBox {
  .el-dialog {
    border-radius: 5px;
    overflow: hidden;
    height: 600px;
  }
  .el-dialog__header {
    background: #fff;
  }
  .el-tabs--border-card > .el-tabs__content {
    padding: 0;
  }
  .el-dialog--center .el-dialog__body {
    padding: 0;
  }
  .el-tab-pane {
    overflow: hidden;
    height: 540px;
  }
  .el-tabs__item {
    text-align: center;
    display: inline-block;
  }
  .el-tabs--border-card {
    border: 0;
    box-shadow: 0 0 0;
  }
  .el-tabs__nav-scroll {
    background: #fff;
  }
  .el-dialog__header {
    padding: 0;
    .el-dialog__close {
      color: #333;
      &:hover {
        color: #ef7f48;
      }
    }
    .el-dialog__headerbtn {
      z-index: 3;
      top: 15px;
      right: 10px;
    }
  }
  .tab {
    text-align: center;
    overflow: hidden;
    height: 60px;
    .item {
      display: inline-block;
      height: 60px;
      line-height: 60px;
      cursor: pointer;
      margin: 0 20px;
      &:hover,
      &.is-active {
        color: #ef7f48 !important;
      }
    }
  }
}

/* 首页左侧广告位  */

.ad-left-fixed {
  position: fixed;
  top: 20%;
  left: 25px;
  display: block;
  z-index: 999;
}
.ad-left-fixed i {
  position: absolute;
  top: -15px;
  right: 0px;
}

/* 屏幕小于1920隐藏 */
/*  @media screen and (min-width: 1920px) {
  .ad-left-fixed {
    position: fixed;
    top: 20%;
    left: 25px;
    display: block;
  }
}  */
/* 首页左侧广告位  */
</style>
