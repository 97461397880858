var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"app"}},[_c('Head'),(_vm.isRouterAlive)?_c('router-view'):_vm._e(),_c('Foot'),_c('ul',{staticClass:"fixed-bar",attrs:{"id":"fixed-bar"}},[_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.showGotop),expression:"showGotop"}],staticClass:"gotop",on:{"click":function($event){return _vm.goTop()}}},[_c('a'),_vm._m(0)]),_vm._m(1),_vm._m(2),_vm._m(3),_c('li',{staticClass:"download"},[_c('a'),_c('div',{staticClass:"subbox"},[_c('div',[_c('img',{staticClass:"qrcode",attrs:{"src":_vm.qrcode}})]),_c('div',[_vm._v("扫码立即下载千途App")])])]),_c('li',{staticClass:"video"},[_vm._m(4),_c('div',{staticClass:"subbox"},[_vm._l((_vm.list),function(item,index){return _c('div',{key:index,class:_vm.cur == index ? 'item item-p on' : 'item item-p',on:{"mouseenter":function($event){_vm.cur = index}}},[_c('p',[_vm._v(_vm._s(item.title1)),_c('br'),_vm._v(_vm._s(item.title2))]),_c('a',{staticClass:"pic",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showVideo(index)}}},[_c('img',{attrs:{"src":item.image}})])])}),_vm._m(5)],2)])]),_c('el-dialog',{staticClass:"videoBox",attrs:{"visible":_vm.isShowVideo,"width":"960","center":""},on:{"update:visible":function($event){_vm.isShowVideo=$event}}},[_c('div',{staticClass:"tab"},_vm._l((_vm.list),function(item,index){return _c('div',{key:index,staticClass:"item",class:_vm.cur == index ? 'is-active' : '',attrs:{"label":item.title1 + item.title2},on:{"click":function($event){return _vm.tab(index)}}},[_vm._v(" "+_vm._s(item.title1)+_vm._s(item.title2)+" ")])}),0),_c('video',{staticClass:"fixed-video",attrs:{"controls":"","src":_vm.videoLink,"autoplay":""}},[_vm._v(" 您的浏览器不支持视频播放。 ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"subbox"},[_c('div',[_vm._v("返回顶部")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"one-click"},[_c('a',{attrs:{"href":"https://www.pai2345.com/web/member/index#/proxy-order","target":"_blank"}},[_vm._v("自动"),_c('br'),_vm._v("投稿")]),_c('div',{staticClass:"subbox"},[_c('a',{attrs:{"href":"https://www.pai2345.com/web/member/index#/proxy-order"}},[_c('img',{attrs:{"src":"https://www.pai2345.com/static/web/res/images/ad-2021-1-28-r.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"one-click"},[_c('a',{attrs:{"href":"//imgstore.pai2345.com","target":"_blank"}},[_vm._v("签约"),_c('br'),_vm._v("售图")]),_c('div',{staticClass:"subbox"},[_c('a',{attrs:{"href":"//imgstore.pai2345.com"}},[_c('img',{attrs:{"src":"https://www.pai2345.com/static/web/res/images/210429/ad-210429-1.png","alt":""}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"service"},[_c('a'),_c('div',{staticClass:"subbox"},[_c('div',{staticClass:"t-1"},[_c('i'),_vm._v("在线客服")]),_c('div',[_vm._v("工作日：9:00 - 22:00")]),_c('div',[_vm._v("节假日：9:00 - 18:00")]),_c('a',{staticClass:"btn-serv",attrs:{"target":"_blank","href":"http://wpa.qq.com/msgrd?v=3&uin=3298507498&site=qq&menu=yes"}},[_vm._v("联系客服")]),_c('div',{staticClass:"t-2"},[_c('a',{attrs:{"href":"http://www.pai2345.com/web/ps/qa","target":"_blank"}},[_c('i'),_vm._v("常见问题>>")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('a',[_vm._v("视频"),_c('br'),_vm._v("教程")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item"},[_c('a',{attrs:{"href":"http://app.hgzxjy.com.cn/cms/a/53","target":"_blank"}},[_vm._v("更多视频>>")])])
}]

export { render, staticRenderFns }