import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import tools from "./utils/common.js";
import AmapVue from '@amap/amap-vue'
AmapVue.config.key = 'e1528e31823f6594189efca741f987c3'
AmapVue.loadPlugins(
    'AMap.Autocomplete', 'AMap.Geocoder',
)
Vue.use(AmapVue)
// 通用样式
import "./static/res/css/reset.css";
// import "swiper/css/swiper.min.css";
// require("swiper/dist/css/swiper.css");
import "./static/res/css/common.css";
import "./static/css/match.css";

// 加载瀑布流插件
import waterfall from "vue-waterfall2";
Vue.use(waterfall);
// 加载懒加载插件
import VueLazyload from "vue-lazyload";
Vue.use(VueLazyload);

// 加载高德地图
import VueAMap from "vue-amap";
Vue.use(VueAMap);
VueAMap.initAMapApiLoader({
  key: "e1528e31823f6594189efca741f987c3",
  plugin: [
    "AMap.Scale",
    "AMap.OverView",
    "AMap.ToolBar",
    "AMap.MapType",
    "AMap.PlaceSearch",
    "AMap.Geolocation",
    "AMap.Geocoder",
  ],
  v: "1.4.4",
  uiVersion: "1.0",
});

// 加载element-ui
import "element-ui/lib/theme-chalk/index.css";

import {
  Dialog,
  Form,
  Input,
  FormItem,
  Select,
  Option,
  Button,
  Message,
  RadioGroup,
  RadioButton,
  Upload,
  Tabs,
  TabPane,
  MessageBox,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  cascader,
  Descriptions,
  DescriptionsItem,
  Table,
  TableColumn,
  Row,
  Col,
  DatePicker,
  Switch,
  CheckboxGroup,
  Checkbox,
  Radio,
  Image,
  Empty,
  // Scrollbar
} from "element-ui";
Vue.use(Dialog);
Vue.use(Form);
Vue.use(Input);
Vue.use(FormItem);
Vue.use(Select);
Vue.use(Option);
Vue.use(Button);
// Vue.use(Scrollbar);
Vue.use(RadioGroup);
Vue.use(RadioButton);
Vue.use(Upload);
Vue.use(Tabs);
Vue.use(TabPane);
Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);
Vue.use(cascader);
Vue.use(Descriptions);
Vue.use(DescriptionsItem);
Vue.use(Table);
Vue.use(TableColumn);
Vue.use(Row);
Vue.use(Col);
Vue.use(DatePicker);
Vue.use(Switch);
Vue.use(CheckboxGroup);
Vue.use(Checkbox);
Vue.use(Radio);
Vue.use(Image);
Vue.use(Empty);

// Vue.use(MessageBox);
// Vue.use(Message);
// message用use会导致每次加载默认弹窗一次
// Vue.component(Message);
// message额外引入
Vue.prototype.$message = Message;
Vue.prototype.$msgbox = MessageBox;
Vue.prototype.$alert = MessageBox.alert;
Vue.prototype.$confirm = MessageBox.confirm;
Vue.prototype.$prompt = MessageBox.prompt;

Vue.config.productionTip = false;

import axios from "axios";
Vue.prototype.axios = axios;
Vue.prototype.tools = tools;
Vue.prototype.urlsite = process.env.VUE_APP_BASE_URL;
if (process.env.NODE_ENV == "production") {
  axios.defaults.baseURL = Vue.prototype.urlsite + "/matchweb";
}

// 引入ajax
// import ajax from "@/utils/ajax";
// Vue.use(ajax);
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
