import axios from "axios";
import store from "@/store";

const utils = {
    // 登录判断与用户信息初始化
    // 仅用于路由判断
    checkLogin() {
        axios.post("/match/checktUserInfo").then(res => {
            if (res.data.code == 200) {
                this.initUserInfo(res);
                store.commit("changeLogin", true);
            }
        })
    },
    // 初始化用户信息
    initUserInfo(userinfo) {
        // if (userinfo.isLogin) {
        // 如果已登录，header 载入已登录信息
        // 否则 next()
        // loadUserInfo();
        // console.log(userinfo);
        // }
        //  else {
        //   if (typeof callback == "function") {
        //     callback();
        //   }
        // }
        // console.log("userinfo： ", userinfo);
    },
    // 分享组件
    shareTo(stype) {
        var ftit = "";
        var flink = "";
        var lk = "";
        //获取文章标题
        ftit = document.title;
        //获取网页中内容的第一张图片地址作为分享图
        flink = document.images[0].src;
        if (typeof flink == "undefined") {
            flink = "";
        }
        //当内容中没有图片时，设置分享图片为网站logo
        if (flink == "") {
            lk = "http://" + window.location.host + "/images/logo.png";
        }
        //如果是上传的图片则进行绝对路径拼接
        if (flink.indexOf("/uploads/") != -1) {
            lk = "http://" + window.location.host + flink;
        }
        //百度编辑器自带图片获取
        if (flink.indexOf("ueditor") != -1) {
            lk = flink;
        }
        // 使用移动端的地址
        let url = document.location.href;
        url = url.replace('match-fed', 'match-mobile');
        //qq空间接口的传参
        if (stype == "qzone") {
            window.open(
                "https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=" +
                url +
                "?sharesource=qzone&title=" +
                ftit +
                "&pics=" +
                lk +
                "&summary=" +
                document
                    .querySelector('meta[name="description"]')
                    .getAttribute("content")
            );
        }
        //新浪微博接口的传参
        if (stype == "weibo") {
            window.open(
                "http://service.weibo.com/share/share.php?url=" +
                url +
                "?sharesource=weibo&title=" +
                ftit +
                "&pic=" +
                lk +
                "&appkey=2706825840"
            );
        }
        //qq好友接口的传参
        if (stype == "qq") {
            window.open(
                "http://connect.qq.com/widget/shareqq/index.html?url=" +
                url +
                "?sharesource=qzone&title=" +
                ftit +
                "&pics=" +
                lk +
                "&summary=" +
                document
                    .querySelector('meta[name="description"]')
                    .getAttribute("content") +
                "&desc=千途导摄，一个优秀摄影师聚集的网站"
            );
        }
        //生成二维码给微信扫描分享，php生成，也可以用jquery.qrcode.js插件实现二维码生成
        // if (stype == "wechat") {
        //   window.open(
        //     "http://zixuephp.net/inc/qrcode_img.php?url=http://zixuephp.net/article-1.html"
        //   );
        // }
    }
};

// module.exports = utils;
export default utils;