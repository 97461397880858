<template>
  <div class="match-focus">
    <div class="swiper-container" id="mImg">
      <div class="swiper-wrapper">
        <div class="swiper-slide pointer" v-for="item in slideList" :key="item.id">
          <img :src="item.image" @click="itemClick(item)"/>
        </div>
      </div>
    </div>
    <div class="swiper-container swiper-container-thumbs" id="mTitle">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide pointer"
          :id="item.id"
          v-for="(item, index) in slideList"
          :key="index"
          @mouseenter="showCurThum(index + 1)"
          @click="itemClick(item)"
        >
          <!--<router-link  :to="-->
              <!--item.moonmatch_type == 1-->
                <!--? '/month/' + item.id-->
                <!--: '/detail/' + item.id-->
            <!--">-->
            <div class="title-1">{{ item.mname }}</div>
            <div class="title-2">{{ item.sponsor }}</div>
          <!--</router-link>-->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Swiper from "swiper/js/swiper.js";
export default {
  data() {
    return {
      slideList: [
        // {
        //   id: 1,
        //   image: require("../static/res/images/pscase_after1.jpg"),
        //   link: "description.html",
        //   mname: "标题1标题1",
        //   sponsor: "主办：福州市摄影家协会"
        // },
      ],
      swiper: ""
    };
  },
  methods: {
    init() {
      this.swiper = new Swiper("#mImg", {
        initialSlide: 0,
        spaceBetween: 10,
        effect: "fade",
        loop: true,
        autoplay: {
          delay: 3000, //3秒切换一次
          disableOnInteraction: false
        },
        thumbs: {
          swiper: {
            el: "#mTitle",
            spaceBetween: 0,
            slidesPerView: 4,
            watchSlidesVisibility: true /*避免出现bug*/,
            direction: "vertical"
          }
        },
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true //修改swiper的父元素时，自动初始化swiper
        // onSlideChangeEnd: function(swiper) {
        //   swiper.update();
        //   this.swiper.startAutoplay();
        //   this.swiper.reLoop();
        // }
      });
    },
    showCurThum(index) {
      this.swiper.slideTo(index, 1000, false);
    },
    itemClick(obj){
      if(obj.match_topics == 1){
          this.$router.push({
              path: '/month/'+ obj.id
          });
      }else {
          // console.log(this.urlsite+'/matchweb/match/webDetail/id/'+ obj.id);
          window.location.href = this.urlsite+'/matchweb/match/webDetail/id/'+ obj.id;
      }
    }
  },
  created() {
    // let that = this;
    // this.axios("/getSwiperList?id=1").then(res => {
    //   that.slideList = res.data.data;
    //   // setTimeout(this.initSwiper(), 6000);
    //   // console.log(that.slideList);
    // });
  },
  mounted() {
    // 轮播图ajax请求写这边
    // this.init();

    let that = this;
    this.axios.post("/match/getSwiperList").then(res => {
      that.slideList = res.data.data;

      this.$nextTick(function() {
        that.init();
      });
      // setTimeout(this.initSwiper(), 6000);
      // console.log(that.slideList);
    });
  }
};
</script>

<style lang="scss">
@import "../../node_modules/swiper/css/swiper.min.css";
.pointer{
  cursor: pointer;
}
</style>
