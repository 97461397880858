import SparkMD5 from "spark-md5";
import axios from "axios";

var tools = {
  api: {
    isTeamMember: "/team/isTeamMember", // 判断战队信息
    teamInfo: "/team/teamInfo", // 战队信息
    contributeTeam: "/team/join_single", // 战队投稿和内容修改
    getWork: "match/get_work", // 查看作品
    delGroupWorks: "/team/delmyworks", //删除帖子
    teamWorksList: "/team/h5List", // 获取战队个人作品列表
  },
  files2path($files, $path, only_folder) {
    only_folder = typeof only_folder == "undefined" ? 0 : 1;
    let input = Object.keys($files);
    var output = [];
    for (var i = 0; i < input.length; i++) {
      var chain = input[i].split("/");
      var currentNode = output;
      for (var j = 0; j < chain.length; j++) {
        if (chain[j] === "") {
          break;
        }
        if (only_folder == 1 && j >= chain.length - 1) {
          break;
        }
        var wantedNode = chain[j];
        var lastNode = currentNode;
        for (var k = 0; k < currentNode.length; k++) {
          if (currentNode[k].name == wantedNode) {
            currentNode = currentNode[k].children;
            break;
          }
        }
        if (lastNode == currentNode) {
          //创造对于层级的key
          var node = input[i];
          var newNode = (currentNode[k] = {
            key: chain.slice(0, j + 1).join("/"),
            name: wantedNode,
            is_folder: node.charAt(node.length - 1) == "/" ? 1 : 0,
            //loaded: $files[node].loaded,
            url: $files[node].url,
            thumb: $files[node].thumb,
            // big: $files[node].big,
            // state: $files[node].state,
            // address: $files[node].address,
            // lng: $files[node].lng,
            // lat: $files[node].lat,
            // create_at: $files[node].create_at,
            // download: $files[node].download,
            //id: $files[node].id,
            children: [],
            spread: true,
          });
          currentNode = newNode.children;
        } else {
          delete currentNode.children;
        }
      }
    }
    let r = 0;
    let arr = $path.split("/");
    let key = "";
    for (var m = 0; m < arr.length; m++) {
      if (arr[m] != "") {
        key = key == "" ? arr[m] : key + "/" + arr[m];
        for (var y = 0; y < output.length; y++) {
          if (output[y].key == key) {
            r = 1;
            output = output[y]["children"];
            break;
          } else {
            r = 0;
          }
        }
      }
    }
    return r == 1 ? output : [];
  },
  getOldImg() {
    var c = new Promise((resolve) => {
      axios.post("/match/webOldImg").then((res) => {
        resolve(res.data.data);
      });
    });
    return c;
  },
  getMd5(file) {
    var p = new Promise((resolve, reject) => {
      var fileReader = new FileReader();
      var dataFile = file;
      var spark = new SparkMD5(); //创建md5对象（基于SparkMD5）
      if (dataFile.size > 1024 * 1024 * 20) {
        var data1 = dataFile.slice(0, 1024 * 1024 * 20); //将文件进行分块 file.slice(start,length)
        fileReader.readAsBinaryString(data1); //将文件读取为二进制码
      } else {
        fileReader.readAsBinaryString(dataFile);
      }
      //文件读取完毕之后的处理
      fileReader.onload = function(e) {
        spark.appendBinary(e.target.result);
        var md5 = spark.end();
        if (md5) {
          resolve(md5);
        } else {
          reject();
        }
      };
    });
    return p;
  },
  callbackOss(file, callback) {
    let formData = new FormData();
    formData.append("key", file.key);
    formData.append("policy", file.policy);
    formData.append("OSSAccessKeyId", file.OSSAccessKeyId);
    formData.append("success_action_status", "200"); // 让服务端返回200,不然，默认会返回204
    formData.append("callback", "");
    formData.append("signature", file.signature);
    formData.append("file", file);

    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    };
    var uploadUrl = file.url;
    if (process.env.NODE_ENV == "development") {
      uploadUrl = "/aly";
    }
    //正式包file.url
    axios.post(uploadUrl, formData, config).then(function(result) {
      var imgPath = "";
      if (result.status === 200) {
        imgPath = file.url + "/" + file.key;
      }
      if (callback) {
        callback(imgPath);
      }
    });
  },
  uploadaly(file) {
    var c = new Promise((resolve, reject) => {
      var that = this;
      that.getMd5(file).then((md5) => {
        file.md5 = md5;
        axios
          .post("/match/params", { name: file.name, md5: md5 })
          .then((res) => {
            if (res.status == 200) {
              var obj = res.data.data;
              file.key = obj.key;
              file.policy = obj.policy;
              file.OSSAccessKeyId = obj.id;
              file.signature = obj.signature;
              file.url = obj.url;
              that.callbackOss(file, function(res) {
                if (res) {
                  resolve(res);
                } else {
                  reject(false);
                }
              });
            } else {
              reject(false);
            }
          });
      });
    });
    return c;
  },
  hasImgType(arr, str) {
    for (var i = 0; i < arr.length; i++) {
      if (str.indexOf(arr[i]) != -1) {
        break;
      }
    }
    if (i < arr.length) {
      return true;
    } else {
      return false;
    }
  },
  getMatchDetail(obj) {
    var that = this;
    var d = new Promise((resolve) => {
      var data = { custom_fields: {}, gourp_limit: {}, match_limit: {} };
      that.getDetail(obj).then((res) => {
        data.custom_fields = res.custom_fields;
        data.match_limit = res;
        data.gourp_limit = that.return_group(res.match_group, obj.group);
        resolve(data);
      });
    });
    return d;
  },
  getWorksDetail(obj) {
    var d = new Promise((resolve, reject) => {
      axios.post("/match/getWorksDetail", obj).then((res) => {
        if (res.status == 200) {
          resolve(res.data.data);
        } else {
          reject(res);
        }
      });
    });
    return d;
  },
  getDetail(obj) {
    var that = this;
    var d = new Promise((resolve) => {
      that.detail_cache(obj, false).then((res) => {
        resolve(res);
      });
    });
    return d;
  },
  getAreaList() {
    var d = new Promise((resolve, reject) => {
      axios.post("/match/getAreaList").then((res) => {
        if (res.status == 200) {
          resolve(res.data.data);
        } else {
          reject(res);
        }
      });
    });
    return d;
  },
  getAreas() {
    var d = new Promise((resolve, reject) => {
      var list = sessionStorage.getItem("match_areas");
      if (!list || list == "undefined") {
        axios.post("/match/areas").then((res) => {
          if (res.status == 200) {
            sessionStorage.setItem(
              "match_areas",
              JSON.stringify(res.data.data)
            );
            resolve(res.data.data);
          } else {
            reject(res);
          }
        });
      } else {
        list = JSON.parse(list);
        resolve(list);
      }
    });
    return d;
  },
  getUrlParam(name) {
    var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
    var r = window.location.search.substr(1).match(reg); //匹配目标参数
    if (r != null) return decodeURI(r[2]);
    return null; //返回参数值
  },
  return_group(arr, gid) {
    for (var i = 0; i < arr.length; i++) {
      if (gid == arr[i].gid) {
        return arr[i];
      }
    }
    return false;
  },
  detail_cache(obj, init) {
    var c = new Promise((resolve, reject) => {
      var cache_name = "match_detail" + obj.id;
      var detail = sessionStorage.getItem(cache_name);
      if (init || !detail || detail == "undefined") {
        axios.post("/match/detail", obj).then((res) => {
          if (res.status == 200) {
            sessionStorage.setItem(cache_name, JSON.stringify(res.data.data));
            resolve(res.data.data);
          } else {
            reject(res);
          }
        });
      } else {
        detail = JSON.parse(detail);
        resolve(detail);
      }
    });
    return c;
  },
  ajax_error(err) {
    console.log(err);
    try {
      // if(typeof passportLogin == "undefined"){
      //     var passportLogin ={login:function () {
      //             console.log('本地运行');
      //         }}
      // }
      /*eslint-disable*/
      passportLogin.login();
      /*eslint-enable*/
    } catch (e) {
      console.log(e);
    }
    // if(passportLogin ){
    //     passportLogin.login();
    // }
  },
  //获取文件MD5
  getFileMd5(file, callback) {
    const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
    const fileReader = new FileReader();
    const chunkSize = 2097152;
    const chunks = Math.ceil(file.size / chunkSize);
    let currentChunk = 0;
    const spark = new SparkMD5.ArrayBuffer();
    fileReader.onload = function (e) {
      spark.append(e.target.result)
      currentChunk++
      if (currentChunk < chunks) {
        loadNext()
      } else {
        callback && callback(spark.end());
      }
    }
    fileReader.onerror = function () {
      console.warn('FileReader error.')
    }

    function loadNext() {
      const start = currentChunk * chunkSize
      const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize
      // 注意这里的 fileRaw
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
    }

    loadNext()
  },
  // 时间转换
  extractDate(dateStr) {
    let date = new Date(dateStr);
    let year = date.getFullYear();
    let month = date.getMonth() + 1; // 月份+1，因为getMonth()返回0-11
    let day = date.getDate();
    // 使用双位数字格式化月份和日期
    let formatNumber = (n) => String(n).padStart(2, '0');
    return `${year}-${formatNumber(month)}-${formatNumber(day)}`;
  },
};
export default tools;
