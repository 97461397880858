<template>
  <!-- <Head /> -->
  <div class="content">
    <div class="wrap">
      <ThumbSlide />
      <div class="mbar">
        <div
          class="mbar-right"
          @mouseleave="showHot = false"
          @mouseenter="showHot = true"
        >
          <div class="mbar-inner">
            <input
              @click="showHot = true"
              type="text"
              v-model="keyword"
              placeholder="请输入搜索内容"
            />
            <el-button icon="el-icon-search" @click="hotSearch()"></el-button>
          </div>
          <dl class="mbar-drop" v-show="showHot">
            <dt class="hot-search"><i class="ico ico-hot"></i>热门搜索</dt>
            <dd
              class="hot-search"
              v-for="(i, key) in keywords"
              v-bind:key="key"
              @click="keyClick(i)"
            >
              <i>{{ key + 1 }}</i
              ><span>{{ i }}</span>
            </dd>
          </dl>
        </div>
        <!-- <a href="https://www.pai2345.com/web/proxymatch/index"><div class="ad-center"></div></a> -->
        <el-tabs v-model="activeName" @tab-click="hotSearch">
          <el-tab-pane label="全部赛事" name="first">
            <ul v-show="!isListEmpty" class="match-list">
              <li
                v-for="(item, index) in matchList"
                :key="index"
                @click="itemClick(item)"
              >
                <div class="pic">
                  <img :src="item.image" />
                </div>
                <div class="title-1">{{ item.mname }}</div>
                <div class="title-2">{{ item.sponsor }}</div>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="国协赛事" name="second">
            <ul v-show="!isListEmpty" class="match-list">
              <li
                v-for="(item, index) in matchList"
                :key="index"
                @click="itemClick(item)"
              >
                <div class="pic">
                  <img :src="item.image" />
                </div>
                <div class="title-1">{{ item.mname }}</div>
                <div class="title-2">{{ item.sponsor }}</div>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="热门赛事" name="third">
            <ul v-show="!isListEmpty" class="match-list">
              <li
                v-for="(item, index) in matchList"
                :key="index"
                @click="itemClick(item)"
              >
                <div class="pic">
                  <img :src="item.image" />
                </div>
                <div class="title-1">{{ item.mname }}</div>
                <div class="title-2">{{ item.sponsor }}</div>
              </li>
            </ul>
          </el-tab-pane>
          <el-tab-pane label="获奖公布" name="fourth">
            <ul class="match-award-list">
              <li v-for="(i, key) in prizesList" :key="key">
                <div class="hd">
                  <div v-if="i.notice_link != ''" class="fl">
                    <i class="ico ico-announce"></i>
                    <span class="title">{{ i.mname }}</span>
                  </div>
                  <div v-else class="fl">
                    <i class="ico ico-award"></i>
                    <span class="title">{{ i.mname }}</span>
                    <i class="ico ico-clock"></i>
                    <span class="update-time">{{ i.prizesStr }}</span>
                  </div>
                  <div v-if="i.notice_link != ''" class="fr">
                    <a :href="i.notice_link" class="el-button" target="_blank">点击查看</a>
                  </div>
                  <div v-else class="fr" @click="prizesClick(i.id)">
                    <el-button>查看更多</el-button>
                  </div>
                </div>
                <div class="bd">
                  <ul>
                    <li
                      v-for="(img, index) in i.children"
                      :key="index"
                      v-show="index < 6"
                    >
                      <div class="imgbox"><img :src="img.image" alt="" /></div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </el-tab-pane>
        </el-tabs>
      </div>

      <div class="match-list match-empty" v-show="isListEmpty"></div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
// import Head from "@/layouts/Head.vue";
import ThumbSlide from "@/components/ThumbSlide.vue";

export default {
  name: "Home",
  components: {
    // Head,
    ThumbSlide,
  },
  data: function() {
    return {
      demo: require("@/static/images/pic-3.png"),
      demo1: require("@/static/images/pic-10.png"),
      // 缓存 matchList 初始化列表，用 list 筛选赋值
      list: [],
      matchList: [],
      statusClass: "item",
      isListEmpty: false,
      // 是否显示热搜
      showHot: false,
      // 样式切换
      cur: "",
      isShowFilter: true,
      // 搜索关键字
      status: ["全部", "投稿中", "即将开始", "已截稿"],
      // 显示的关键字
      keywords: [],
      activeName: "first",
      page: 1,
      limit: 12,
      totalPages: 2,
      keyword: "",
      busy: true,
      state: 0,
      is_hot: 0,
      informationList: [],
      prizesList: [],
    };
  },
  mounted() {
    var that = this;
    window.addEventListener(
      "scroll",
      () => {
        that.load();
      },
      false
    );
  },
  methods: {
    keyClick(str) {
      this.keyword = str;
    },
    initMatchList() {
      this.busy = false;
      this.axios
        .post("/match/newMatchList", {
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
          state: this.state,
          is_hot: this.is_hot,
        })
        .then((res) => {
          if (this.page == 1) {
            this.totalPages = res.data.data.totalPages;
            this.matchList = res.data.data.data;
            this.isListEmpty = this.matchList.length > 0 ? false : true;
          } else {
            this.matchList = this.matchList.concat(res.data.data.data);
          }
          this.busy = true;
        });
    },
    information() {
      this.busy = false;
      this.axios
        .post("/api/information", {
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
        })
        .then((res) => {
          if (this.page == 1) {
            this.totalPages = res.data.data.totalPages;
            this.informationList = res.data.data.data;
            this.isListEmpty = this.informationList.length > 0 ? false : true;
          } else {
            this.informationList = this.informationList.concat(
              res.data.data.data
            );
          }
          this.busy = true;
        });
    },
    prizes() {
      this.busy = false;
      this.axios
        .post("/api/prizesList", {
          page: this.page,
          limit: this.limit,
          keyword: this.keyword,
        })
        .then((res) => {
          if (this.page == 1) {
            this.totalPages = res.data.data.totalPages;
            this.prizesList = res.data.data.data;
            this.isListEmpty = this.prizesList.length > 0 ? false : true;
          } else {
            this.prizesList = this.prizesList.concat(res.data.data.data);
          }
          this.busy = true;
        });
    },

    //滚动条在Y轴上的滚动距离
    getScrollTop() {
      var documentScrollTop = 0;
      documentScrollTop = Math.floor(
        document.body.scrollTop ||
          document.documentElement.scrollTop ||
          window.pageXOffset
      );
      return documentScrollTop;
    },
    //文档的总高度
    getScrollHeight() {
      var documentScrollHeight = 0;
      documentScrollHeight = document.body.scrollHeight;
      return documentScrollHeight;
    },
    //浏览器视口的高度
    getWindowHeight() {
      var windowHeight = 0;
      windowHeight = document.documentElement.clientHeight;
      return windowHeight;
    },
    load() {
      if (this.busy) {
        let scroll =
          this.getScrollTop() + this.getWindowHeight() - this.getScrollHeight();
        if (scroll > -150) {
          this.page++;
          if (this.page <= this.totalPages) {
            this.init();
          }
        }
      }
    },
    init() {
      if (this.activeName == "first") {
        this.state = 0;
        this.is_hot = 0;
        this.initMatchList();
      } else if (this.activeName == "second") {
        this.state = 1;
        this.is_hot = 0;
        this.initMatchList();
      } else if (this.activeName == "third") {
        this.state = 0;
        this.is_hot = 1;
        this.initMatchList();
      } else if (this.activeName == "fourth") {
        this.prizes();
      }
    },
    itemClick(obj) {
      if (obj.match_topics == 1) {
        this.$router.push({
          path: "/month/" + obj.id,
        });
      } else {
        if (process.env.NODE_ENV == "development") {
          this.$router.push({
            path: "/detail/" + obj.id + "/intro",
          });
        } else {
          window.location.href =
            this.urlsite + "/matchweb/match/webDetail/id/" + obj.id;
        }
      }
    },
    prizesClick(id) {
      window.location.href =
        this.urlsite + "/matchweb/match/webDetail/id/" + id;
    },
    infoItemClick(obj) {
      this.axios.post("/match/add_visit", { id: obj.id });
      this.$router.push({
        path: "/detail-info/" + obj.id,
      });
    },
    filterMatchList() {
      for (let item of this.keywords) {
        if (item.id == "state") {
          this.keys.state = this.filterState(item.condition);
        }
        if (item.id == "region") {
          this.filterRegion(item.type, item.condition);
        }
      }
      this.initMatchList();
    },
    hotSearch() {
      this.page = 1;
      this.totalPages = 2;
      this.showHot = false;
      this.init();
    },
    tab(index) {
      this.cur = index;
    },
  },
  created() {
    this.axios.post("/api/hotList").then((res) => {
      this.keywords = res.data.data;
    });
    this.initMatchList();
  },
};
</script>

<style lang="scss">
.content {
  overflow: hidden;
}
.mbar {
  position: relative;
  .el-tabs__header {
    height: 62px;
    background: #fff;
    margin-bottom: 20px;
    border-radius: 4px;
  }
  .el-tabs__item {
    float: left;
    margin: 16px 0 0 30px;
    text-align: center;
    padding: 0 15px !important;
    line-height: 30px;
    height: 30px;
    background: #fff;
    color: #f77e5e;
    box-shadow: 0 0 10px rgba(253, 150, 118, 0.2);
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    box-sizing: content-box;
    // transition: all 0.3s ease;
    &:hover,
    &.is-active {
      background: #f77e5e;
      color: #fff;
    }
  }
  .el-tabs--card > .el-tabs__header .el-tabs__nav {
    border: 0;
  }
  .el-tabs--card > .el-tabs__header .el-tabs__item.is-active,
  .el-tabs--card > .el-tabs__header .el-tabs__item:first-child {
    border-width: 0;
  }
  .mbar-right {
    // float: right;
    position: absolute;
    right: 30px;
    top: 14px;
    z-index: 2;
    width: 236px;

    border-radius: 18px;
    .mbar-inner {
      width: 236px;
      height: 34px;
      padding: 1px;
      background: #f77e5e;
      border-radius: 18px;
      overflow: hidden;
    }
    .mbar-drop {
      background: #fff;
      margin-top: 8px;
      box-shadow: 0 0 10px rgba(150, 150, 150, 0.3);
      border-radius: 4px;
      padding: 10px 0;
      dt {
        padding: 0 15px;
        margin-bottom: 5px;
        font-size: 14px;
        .ico-clock-black {
          background-position: -250px 0;
          width: 14px;
          height: 14px;
          margin-right: 7px;
        }
        .ico-del {
          background-position: -120px -60px;
          width: 14px;
          height: 14px;
          cursor: pointer;
          &:hover {
            background-position: -160px -60px;
          }
        }
        &.last-search {
          overflow: hidden;
        }
        &.hot-search {
          color: #fc2a2a;
        }
        .ico {
          top: -2px;
          position: relative;
        }
      }
      dd {
        padding: 0 15px;
        font-size: 16px;
        &.hot-search {
          line-height: 28px;
          color: #666;
          cursor: pointer;
        }
        &.last-search {
          span {
            background: #f8f8f8;
            padding: 0 10px;
            height: 24px;
            line-height: 23px;
            box-sizing: border-box;
            max-width: 100%;
            white-space: nowrap;
            text-align: center;
            min-width: 50px;
            overflow: hidden;
            text-overflow: ellipsis;
            cursor: pointer;
            color: #666;
            margin: 5px 10px 5px 0;
            &:hover {
              color: #f77e5e;
            }
          }
        }

        i {
          background: #f77e5e;
          border-radius: 2px;
          color: #fff;
          text-align: center;
          line-height: 16px;
          height: 16px;
          width: 16px;
          font-size: 14px;
          font-style: normal;
          margin: 0 7px 0 0;
          position: relative;
          top: 6px;
          font-family: Helvetica, Arial, sans-serif;
        }
        &:nth-child(n + 5) i {
          background: #ccc;
        }
        i,
        span {
          display: inline-block;
          vertical-align: top;
        }
        &.hot-search:hover {
          background: #f8f8f8;
        }
      }
    }
    // margin: 13px 30px 0 0;
    & input,
    & .el-button {
      float: left;
    }
    input {
      width: 160px;
      background: #fff;
      height: 24px;
      line-height: 24px;
      padding: 5px 15px;
      border: 0;
      font-size: 14px;
      border-top-left-radius: 18px;
      border-bottom-left-radius: 18px;
    }
    .el-button {
      width: 44px;
      text-align: center;
      height: 34px;
      line-height: 1;
      background: none;
      border: 0;
      transition: all 0.3s ease;
      &:hover {
        background: #fd9676;
      }
      .el-icon-search {
        font-size: 20px;
        color: #fff;
        position: relative;
        top: -5px;
        left: -8px;
        &:hover {
          color: #fff;
        }
      }
    }
  }
  .el-tabs--card > .el-tabs__header {
    border-bottom: 0;
  }
  .match-list li {
    cursor: pointer;
    .pic-wait{
      position: relative;
      img{
        filter:blur(4px)
      }
      .wait-text{
        width:160px;
        height:90px;
        position:absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        background:rgba(255,255,255,.2);
        text-align: center;
        line-height: 90px;
        color: #fff;
        font-size: 18px;
        text-shadow: 0 0 10px rgba(0,0,0,.7);
      }
    }
  }
  .match-list::after {
    clear: both;
  }
  .el-tabs__nav-wrap,
  .el-tabs__nav-scroll {
    overflow: auto;
    height: 62px;
  }
  .el-tabs__active-bar {
    display: none;
  }
  .el-tabs__nav-wrap::after {
    display: none;
  }

  .ico {
    background: url("../static/images/bg-114.png") no-repeat;
  }
  .ico-hot-top {
    background-position: 0 0;
    width: 53px;
    height: 36px;
  }
  .is-hot .ico-visit {
    background-position: -79px 1px;
    width: 21px;
    height: 21px;
  }
  .ico-hot {
    background-position: -210px 0;
    width: 14px;
    height: 14px;
    margin-right: 7px;
  }
  .ico-clock {
    background-position: -120px 0;
    width: 14px;
    height: 14px;
  }
  .ico-visit {
    background-position: -160px 0;
    width: 21px;
    height: 21px;
  }
  .ico-award {
    background-position: 0 -60px;
    width: 78px;
    height: 28px;
  }
  .ico-announce {
    width: 78px;
    height: 28px;
    margin-right: 10px;
    background: url("../static/images/20220127/i-hjjx.png") no-repeat center center;
  }
  .match-award-list {
    & > li {
      background: #fff;
      margin-bottom: 20px;
      padding-bottom: 25px;
      border-radius: 4px;
      overflow: hidden;
      transition: all 0.3s ease;
      &:hover {
        box-shadow: 0 0 10px rgba(150, 150, 150, 0.3);
      }
      .hd {
        overflow: hidden;
        padding: 10px 25px;
        height: 46px;
        line-height: 46px;
        .ico-award {
          margin-right: 10px;
        }
        .ico-clock {
          margin-right: 4px;
        }
        & span,
        & i {
          display: inline-block;
          vertical-align: middle;
        }
        .title {
          font-size: 18px;
          font-weight: bold;
          padding-right: 20px;
          max-width: 850px;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
        .update-time {
          color: #999;
        }
        .el-button {
          border-color: #f77e5e;
          width: 138px;
          height: 44px;
          line-height: 0.9;
          font-size: 18px;
          background: #fff;
          color: #f77e5e;
          text-align: center;
          &:hover {
            background: #f77e5e;
            color: #fff;
          }
        }
      }
      .bd {
        ul {
          padding: 0 0 0 15px;
        }
        li {
          float: left;
          width: 205px;
          height: 205px;
          margin: 0 10px;
          background: #f1f3f8;
          transition: all 0.3s ease;
          &:hover {
            box-shadow: 0 0 10px rgba(100, 100, 100, 0.4);
          }
          .imgbox {
            border-radius: 4px;
            overflow: hidden;
          }
        }
      }
    }
  }
  .imgbox {
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    img {
      max-width: 100%;
      max-height: 100%;
      transition: all 0.3s ease;
      // position: relative;
      // top: 50%;
      // left: 50%;
      // transform: translate(-50%, -50%);
    }
    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }
}
.match-info {
  li {
    padding: 11px 25px 11px 13px;
    background: #fff;
    border-radius: 4px;
    position: relative;
    margin-bottom: 13px;
    overflow: hidden;
    transition: all 0.3s ease;
    cursor: pointer;
    .pic {
      position: relative;
      width: 257px;
      height: 128px;
      // overflow: hidden;
      float: left;
      background: #f1f3f8;
      .imgbox {
        border-radius: 4px;
      }
      .ico {
        position: absolute;
        top: -5px;
        left: -6px;
        color: #fff;
        font-size: 16px;
        line-height: 25px;
        text-align: center;
      }
    }
    .text {
      margin-left: 290px;
      .h {
        font-weight: bold;
        font-size: 18px;
        height: 28px;
        line-height: 28px;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
      }
      .b {
        color: #666;
        line-height: 22px;
        height: 66px;
        overflow: hidden;
        padding-top: 6px;
      }
      .ico {
        position: relative;
        top: -2px;
        margin-right: 3px;
      }
    }
    .ico-hot-top {
      display: none;
    }
    &.is-hot {
      .hot {
        color: #fc2a2a;
        &::after {
          content: "°";
        }
      }
      .ico-hot-top {
        display: block;
      }
    }
    .f {
      color: #aaa;
      padding-top: 6px;
      overflow: hidden;
      line-height: 22px;
      height: 22px;
    }
    .fl {
      font-size: 14px;
    }
    .fr {
      font-size: 16px;
      font-weight: bold;
    }
    &:hover {
      box-shadow: 0 0 10px rgba(10, 10, 10, 0.15);
      .h,
      .b {
        color: #f77e5e;
      }
    }
  }
}

.el-tabs .el-tabs__content {
  // overflow: auto;
  overflow: initial;
}
.fl {
  float: left;
}
.fr {
  float: right;
}

.ad-center {
  width: 100%;
    background: url(../assets/ad-center202110119.png);
    height:135px;
    background-repeat: no-repeat;
    background-size: contain;
    background-size: 100% 100%;
    margin-bottom: 20px;
 
}
@media screen and (max-width: 1600px) {
  .mbar {
    .match-award-list {
      & > li .bd {
        li {
          width: 175px;
          height: 175px;
        }
      }
    }
  }
}
</style>
